import alejandro from '../img/ferresistemas/perfil/perfil-alejandro.png'
import hedilberto from '../img/ferresistemas/perfil/hceballos.png'
import tomas from '../img/ferresistemas/perfil/tomas.png'
import joel from '../img/ferresistemas/perfil/joel.png'
import juan from '../img/ferresistemas/perfil/juan.png'
import rodrigo from '../img/ferresistemas/perfil/rodrigo.png'
import rodrigog from '../img/ferresistemas/perfil/rodrigog.png'
import miguel from '../img/ferresistemas/perfil/miguel.png'
import hedilbertoc from '../img/ferresistemas/perfil/hedilbertoc.png'
import carlos from '../img/ferresistemas/perfil/carlos.png'
import luis from '../img/ferresistemas/perfil/luis.png'
import sergio from '../img/ferresistemas/perfil/sergio.png'
import daniel from '../img/ferresistemas/perfil/daniel.png'
import sandra from '../img/ferresistemas/perfil/sandra.png'
import candido from '../img/ferresistemas/perfil/candido.png'
import israel from '../img/ferresistemas/perfil/israel.png'
import juana from '../img/ferresistemas/perfil/juana.png'
import angel from '../img/ferresistemas/perfil/angel.png'
import hector from '../img/ferresistemas/perfil/hector.png'
import fernanda from '../img/ferresistemas/perfil/fernanda.png'
import liliana from '../img/ferresistemas/perfil/liliana.png'
import luisdominguez from '../img/ferresistemas/perfil/luisD.png'
import jose from '../img/ferresistemas/perfil/jose.png'
import ricardo from '../img/ferresistemas/perfil/ricardo.png'
import ruben from '../img/ferresistemas/perfil/ruben.png'

import b1 from '../img/ferresistemas/banner/b1.jpg'
import b2 from '../img/ferresistemas/banner/b2.jpg'
import b3 from '../img/ferresistemas/banner/b3.png'
import b4 from '../img/ferresistemas/banner/b4.png'

export const ferreList = [ 'alejandro-cruz', 'hedilbertoceballosa', 'Tomasceballos', 'joelislas', 'juanceballos', 'rodrigoalvarez', 
    'rodrigogarcia', 'miguelislas', 'Hedilbertoceballos', 'Carlosceballos', 'Luisceballos', 'Sergioceballos', 'Danielceballos', 'Sandravarela',
    'Candidoislas', 'Israelmunoz', 'Juanagonzalez', 'Angelmatamoros', 'hectorislas', 'fernandamunoz', 'lilianaalvarado', 'luisdominguez', 'antoniotellez', 'ricardoservin', 'rubenyamil' ]

export const users = {
    lilianaalvarado: {
        path: 'lilianaalvarado',
        component: 'Ferresistemas',
        profile: liliana,
        name: 'Liliana Alvarado Galarza',
        job: 'Comercial',
        tel: '5580097636',
        email: 'liliana.alvarado@ferresistemas.com.mx',
        whats: 'https://wa.me/5215580097636',
        banner: b1
    },
    luisdominguez: {
        path: 'luisdominguez',
        component: 'Ferresistemas',
        profile: luisdominguez,
        name: 'Luis Fernando Domínguez Vázquez',
        job: 'Comercial',
        tel: '5580229586',
        email: 'luis.dominguez@ferresistemas.com.mx',
        whats: 'https://wa.me/5215580229586',
        banner: b2
    },
    alejandrocruz: {
        path: 'alejandro-cruz',
        component: 'Ferresistemas',
        profile: alejandro,
        name: 'Alejandro Cruz P.',
        job: 'Comercial',
        tel: '5543868903',
        email: 'almacen@ferresistemas.com.mx',
        whats: 'https://wa.link/j7nqkw',
        banner: b1
    },
    Hedilbertoceballos: {
        path: 'Hedilbertoceballos',
        component: 'Ferresistemas',
        profile: hedilbertoc,
        name: 'Hedilberto Ceballos A.',
        job: 'Comercial',
        tel: '5539887972',
        email: 'hceballos@ferresistemas.com.mx',
        whats: 'https://wa.link/96383d',
        banner: b2
    },
    Carlosceballos: {
        path: 'Carlosceballos',
        component: 'Ferresistemas',
        profile: carlos,
        name: 'Carlos Ceballos A.',
        job: 'Comercial',
        tel: '5580097658',
        email: 'carlos.ceballos@ferresistemas.com.mx',
        whats: 'https://walink.co/ad7855',
        banner: b1
    },
    Luisceballos: {
        path: 'Luisceballos',
        component: 'Ferresistemas',
        profile: luis,
        name: 'Luis Ceballos A.',
        job: 'Comercial',
        tel: '5549221895',
        email: 'luis.ceballos@ferresistemas.com.mx',
        whats: 'https://wa.link/lniw4l',
        banner: b2
    },
    Sergioceballos: {
        path: 'Sergioceballos',
        component: 'Ferresistemas',
        profile: sergio,
        name: 'Sergio Ceballos A.',
        job: 'Comercial',
        tel: '5580229586',
        email: 'sergio.ceballos@ferresistemas.com.mx',
        whats: 'https://walink.co/c33b6e',
        banner: b1
    },
    Danielceballos: {
        path: 'Danielceballos',
        component: 'Ferresistemas',
        profile: daniel,
        name: 'Daniel Ceballos A.',
        job: 'Comercial',
        tel: '5561390185',
        email: 'daniel.ceballos@ferresistemas.com.mx',
        whats: 'https://walink.co/11acb5',
        banner: b2
    },
    Sandravarela: {
        path: 'Sandravarela',
        component: 'Ferresistemas',
        profile: sandra,
        name: 'Sandra Varela V',
        job: 'Comercial',
        tel: '5580129408',
        email: 'sandra.varela@ferresistemas.com.mx',
        whats: 'https://walink.co/0193f8',
        banner: b1
    },
    Candidoislas: {
        path: 'Candidoislas',
        component: 'Ferresistemas',
        profile: candido,
        name: 'Candido Islas G.',
        job: 'Comercial',
        tel: '5527288568',
        email: 'candido.islas@ferresistemas.com.mx',
        whats: 'https://walink.co/b14d4d',
        banner: b2
    },
    Israelmunoz: {
        path: 'Israelmunoz',
        component: 'Ferresistemas',
        profile: israel,
        name: 'Israel Muñoz H.',
        job: 'Comercial',
        tel: '5543868543',
        email: 'israel.munoz@ferresistemas.com.mx',
        whats: 'https://walink.co/757d07',
        banner: b1
    },
    Juanagonzalez: {
        path: 'Juanagonzalez',
        component: 'Ferresistemas',
        profile: juana,
        name: 'Juana González J.',
        job: 'Comercial',
        tel: '5580097612',
        email: 'cxc@ferresistemas.com.mx',
        whats: 'https://walink.co/a49df8',
        banner: b2
    },
    Angelmatamoros: {
        path: 'Angelmatamoros',
        component: 'Ferresistemas',
        profile: angel,
        name: 'Angel Matamoros',
        job: 'Comercial',
        tel: '5522401631',
        email: 'almacen@ferresistemas.com.mx',
        whats: 'https://walink.co/a58cf2',
        banner: b1
    },
    hectorislas: {
        path: 'hectorislas',
        component: 'Ferresistemas',
        profile: hector,
        name: 'Héctor Islas I.',
        job: 'Comercial',
        tel: '5539550554',
        email: 'hector.islas@ferresistemas.com.mx',
        whats: 'https://wa.link/elypt6',
        banner: b2
    }, 
    fernandamunoz: {
        path: 'fernandamunoz',
        component: 'Ferresistemas',
        profile: fernanda,
        name: 'Fernanda Muñoz M.',
        job: 'Comercial',
        tel: '5580097636',
        email: 'aseguramiento.calidad@ferresistemas.com.mx',
        whats: 'https://walink.co/d890d4',
        banner: b1
    },
    hedilbertoceballosa: {
        path: 'hedilbertoceballosa',
        component: 'Ferresistemas',
        profile: hedilberto,
        name: 'Hedilberto Ceballos A.',
        job: 'Metal Mecánica',
        tel: '5539887972',
        email: 'hceballos@ferresistemas.com.mx',
        whats: 'https://wa.link/96383d',
        banner: b3
    },
    Tomasceballos: {
        path: 'Tomasceballos',
        component: 'Ferresistemas',
        profile: tomas,
        name: 'Tomas Ceballos A.',
        job: 'Metal Mecánica',
        tel: '5531041675',
        email: 'tomas.ceballos@ferresistemas.com.mx',
        whats: 'https://walink.co/96851f',
        banner: b4
    },
    joelislas: {
        path: 'joelislas',
        component: 'Ferresistemas',
        profile: joel,
        name: 'Joel Islas R.',
        job: 'Metal Mecánica',
        tel: '5529036040',
        email: 'joel.islas@ferresistemas.com.mx',
        whats: 'https://wa.link/5dl405',
        banner: b3
    },
    juanceballos: {
        path: 'juanceballos',
        component: 'Ferresistemas',
        profile: juan,
        name: 'Juan Ceballos A.',
        job: 'Metal Mecánica',
        tel: '5539887988',
        email: 'juan.ceballos@ferresistemas.com.mx',
        whats: 'https://wa.link/fqxlpr',
        banner: b4
    },
    rodrigoalvarez: {
        path: 'rodrigoalvarez',
        component: 'Ferresistemas',
        profile: rodrigo,
        name: 'Rodrigo Álvarez L.',
        job: 'Metal Mecánica',
        tel: '5610032458',
        email: 'rodrigo.alvarez@ferresistemas.com.mx',
        whats: 'https://wa.link/vdn5li',
        banner: b3
    },
    rodrigogarcia: {
        path: 'rodrigogarcia',
        component: 'Ferresistemas',
        profile: rodrigog,
        name: 'Rodrigo García M.',
        job: 'Metal Mecánica',
        tel: '5534445230',
        email: 'rodrigo.garcia@ferresistemas.com.mx/',
        whats: 'https://wa.link/3n4m7v',
        banner: b4
    },
    miguelislas: {
        path: 'miguelislas',
        component: 'Ferresistemas',
        profile: miguel,
        name: 'Miguel Islas R.',
        job: 'Metal Mecánica',
        tel: '5545667863',
        email: 'miguel.islas@ferresistemas.com.mx',
        whats: 'https://wa.link/n886v7',
        banner: b3
    },
    antoniotellez: {
        path: 'antoniotellez',
        component: 'Ferresistemas',
        profile: jose,
        name: 'José Antonio Tellez Escobedo',
        job: 'Metal Mecánica',
        tel: '5525251136',
        email: 'antonio.tellez@ferresistemas.com.mx',
        whats: 'https://wa.me/5215525251136',
        banner: b4
    },
    ricardoservin: {
        path: 'ricardoservin',
        component: 'Ferresistemas',
        profile: ricardo,
        name: 'Ricardo Servin Bárcenas',
        job: 'División Industrial',
        tel: '5511090866',
        email: 'ricardo.servin@ferresistemas..mx',
        whats: 'https://wa.me/5215511090866',
        banner: b3
    },
    rubenyamil: {
        path: 'rubenyamil',
        component: 'Ferresistemas',
        profile: ruben,
        name: 'Rubén Yamil Reyes V.',
        job: 'Gerencia de desarrollo de negocios',
        tel: ' 5519491717',
        email: 'ruben.yamil@ferresistemas.com.mx',
        whats: 'https://wa.me/5215519491717',
        banner: b4
    },
}