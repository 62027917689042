import { useState, useEffect } from 'react'

import './App.css';

function Return({ getLogin, showForgoten, btnDisabled, isActive, searchText, showRegister }) {
  useEffect(() => {
    window.location.href = "/"
  }, []);

  return (
    <div className="App">
    </div>
  );
}

export default Return;
