import { users } from './json/ferresistemas';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Slider from "react-slick";

import business from './img/ferresistemas/business.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

import { BoxArrowUpRight, EnvelopeAtFill, Facebook, Instagram, Linkedin, TelephoneFill, Whatsapp } from 'react-bootstrap-icons'

import servicio1 from './img/ferresistemas/servicios1.png'
import servicio2 from './img/ferresistemas/servicios2.png'
import servicio3 from './img/ferresistemas/servicios3.png'
import servicio4 from './img/ferresistemas/servicios4.png'
import servicio5 from './img/ferresistemas/servicios5.png'
import servicio6 from './img/ferresistemas/servicios6.png'
import servicio7 from './img/ferresistemas/servicios7.png'

function App({ path }) {
  const services = [ servicio1, servicio2, servicio3, servicio4, servicio5, servicio6, servicio7 ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div className="App" align="center">
      <div>
        <div className={ users[path].job == 'Comercial' ? 'header-img-a' : 'header-img-b' } ></div>
        <Container className="card-container">
          <Row>
            <div style={{ position: "relative", padding: 0 }}>
              <div className={ users[path].job == 'Comercial' ? 'background-card-b' : 'background-card' } ></div>
              <div style={{ position: "relative", paddingLeft: '15px', paddingRight: '15px' }}>

                { path &&
                  <>
                    <div>
                      <img className='business-image' src={ business }></img>
                    </div>
                    <div>
                      <img className="profile-image" src={ users[path].profile }></img>
                    </div>
                    <div className="profile-name">{ users[path].name }</div>
                    <div className="profile-job">{ users[path].job }</div>

                    <div className="contact-container">
                      <div className='subtitle'>Contáctame</div>
                      <TelephoneFill className="icons-contact" onClick={ () =>  window.open('tel://' + users[path].tel) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <EnvelopeAtFill className="icons-contact" onClick={ () =>  window.open('mailto:' + users[path].email) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Whatsapp className="icons-contact" onClick={ () =>  window.open( users[path].whats ) }/>
                    </div>
                    <div align="center">
                      <br/>
                      <Facebook className='icons-rrss' onClick={ () =>  window.open('https://www.facebook.com/GrupoFerresistemas') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Instagram className='icons-rrss' onClick={ () =>  window.open('https://www.instagram.com/grupoferresistemas/') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Linkedin className='icons-rrss' onClick={ () =>  window.open('https://www.linkedin.com/company/33210937') }/>
                    </div>
                  </>
                }

                <div className='business-desc'>
                  Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.
                </div>

                <div className='business-container'>
                  { path &&
                    <>
                    <div><a href={ 'tel: 5543868903' }> { users[path].tel } </a></div>
                    <div><a href={ 'mailto: almacen@ferresistemas.com.mx' }> { users[path].email } </a></div>
                    </>
                  }
                  <br/>
                  <div><a href={ 'tel: 5558841909' }><b>Tel. Oficina 1:</b> 55 5884 1909 &nbsp;&nbsp;&nbsp;<BoxArrowUpRight className="link-icon" /></a></div>
                  <div><a href={ 'tel: 5558947103' }><b>Tel. Oficina 2:</b> 55 5894 7103 &nbsp;&nbsp;&nbsp;<BoxArrowUpRight className="link-icon" /></a></div>
                  <div><a href={ 'tel: 5522393670' }><b>Tel. Oficina 3:</b> 55 2239 3670 &nbsp;&nbsp;&nbsp;<BoxArrowUpRight className="link-icon" /></a></div>
                  <div><a href={ 'tel: 5558947657' }><b>Tel. Oficina 4:</b> 55 5894 7657 &nbsp;&nbsp;&nbsp;<BoxArrowUpRight className="link-icon" /></a></div>
                  <br/>
                  <div><a href={ 'mailto: ventas@ferresistemas.com.mx' }> ventas@ferresistemas.com.mx </a></div>
                  <div><a href={ 'https://ferresistemas.com.mx/' }> ferresistemas.com.mx </a></div>
                </div>

                <br/>
                <div>
                  <img src={ users[path].banner } className="banner_img" />
                </div>

                <br/>

                <div className='service-carousel-container'>
                  <Slider {...settings}>
                    { services.map( (item, i) => (
                      <div align="center" key={ i + "_c" }>
                        <img
                          className="services-carousel"
                          src={item}
                        />
                      </div>
                    )) }
                  </Slider>
                </div>
              </div>
              <div className="footer">
                <div className="legend">
                    Nuestro compromiso: "Clientes totalmente satisfechos"
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
