import React, { useState, useEffect } from "react";
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import "./App.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe("pk_test_51OEy0qGYJdAolFWkSSlCTGWmCzV2wau9OVvZi1yXb7ZAR9cyhSRv20FCEDakq0BpBmLpWXbPjZ8E9XeOCbOcfhQE00ZJeAdjcO");

const CheckoutForm = ({ uid }) => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    console.log( 'CheckoutForm uid', uid )
    var json = {
      items: [ 'price_1OlfeiGYJdAolFWkommntQFS' ],
      mode: 'suscription',
      uid: uid
    }
    // Create a Checkout Session as soon as the page loads
    axios({
      method: 'post',
      url: "http://192.169.176.97/srv/api/session/create-checkout-session",
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result checkout", result.data)
      setClientSecret(result.data.clientSecret);
    })
    .catch(error => {
      console.log("error", error.message)
    })


    /*fetch("", {
      method: "POST",
      headers: { 'content-type': 'application/json' },
      data: json
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));*/

  }, []);

  const createClient = () => {
    var clientJson = {
      clientId: null,
      name: 'John Doe',
      plan: '1',
      createdTime: new Date(),
      email: 'test@test.com',
      phone: '5555555555'
    }
  }

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default CheckoutForm;

