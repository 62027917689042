import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Slider from "react-slick";

import business from './img/opticas/business.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

import { BoxArrowUpRight, EnvelopeAtFill, Facebook, Globe, Instagram, Linkedin, Telegram, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons'

import andrea from './img/andrea/andrea.png'

import arte1 from './img/andrea/arte1.jpg'
import arte2 from './img/andrea/arte2.jpg'
import arte3 from './img/andrea/arte3.jpg'
import arte4 from './img/andrea/arte4.jpg'
import arte5 from './img/andrea/arte5.jpg'
import arte6 from './img/andrea/arte6.jpg'
import arte7 from './img/andrea/arte7.jpg'
import arte8 from './img/andrea/arte8.jpg'
import arte9 from './img/andrea/arte9.jpg'
import arte10 from './img/andrea/arte10.jpg'
import arte11 from './img/andrea/arte11.jpg'
import arte12 from './img/andrea/arte12.jpg'
import arte13 from './img/andrea/arte13.png'
import arte14 from './img/andrea/arte14.png'
import arte15 from './img/andrea/arte15.png'
import arte16 from './img/andrea/arte16.png'
import arte17 from './img/andrea/arte17.png'
import arte18 from './img/andrea/arte18.png'
import arte19 from './img/andrea/arte19.png'
import arte20 from './img/andrea/arte20.png'
import arte21 from './img/andrea/arte21.png'
import arte22 from './img/andrea/arte22.png'
import arte23 from './img/andrea/arte23.png'
import arte24 from './img/andrea/arte24.png'
import arte25 from './img/andrea/arte25.png'
import arte26 from './img/andrea/arte26.png'
import arte27 from './img/andrea/arte27.png'
import arte28 from './img/andrea/arte28.png'
import arte29 from './img/andrea/arte29.png'

function App({ path }) {
  const obras = [ arte1, arte2, arte3, arte4, arte5, arte6, arte7, arte8, arte9, arte10,
    arte11, arte12, arte13, arte14, arte15, arte16, arte17, arte18, arte19, arte20,
    arte21, arte22, arte23, arte24, arte25, arte26, arte27, arte28 , arte29 ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div className="App" align="center">
      <div>
        <Container className="card-container">
          <Row>
            <div style={{ position: "relative", padding: 0 }}>
              <div className='background-andrea'></div>
              <div style={{ position: "relative", paddingLeft: '15px', paddingRight: '15px' }}>

                <>
                  <div>
                    <img className="profile-image" src={ andrea }></img>
                  </div>
                  <div className="profile-name">Andrea Santana</div>
                  <div className="profile-job">Artista</div>

                  <div className="contact-container">
                    <div className='subtitle'>Contáctame</div>
                    <Facebook className="icons-contact-andrea" onClick={ () =>  window.open('https://www.facebook.com/andreasantana8') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Instagram className="icons-contact-andrea" onClick={ () =>  window.open('https://www.instagram.com/andrea_santana_w') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Tiktok className="icons-contact-andrea" onClick={ () =>  window.open('https://vm.tiktok.com/ZM8CrTmfC/') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Telegram className="icons-contact-andrea" onClick={ () =>  window.open('https://t.me/Andr8id') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Whatsapp className="icons-contact-andrea" onClick={ () =>  window.open('https://walink.co/6eed5d') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <EnvelopeAtFill className="icons-contact-andrea" onClick={ () =>  window.open('andreasantana8@gmail.com') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <TelephoneFill className="icons-contact-andrea" onClick={ () =>  window.open('tel:573173074509') }/>
                  </div>
                </>

                <div className='business-desc'>
                  “Maestra en artes plásticas de la Universidad Nacional de Colombia, con énfasis en imagen bidimensional y especialista en fotografía.”
                </div>
                <div className="section-title">
                  ALGUNAS DE MIS OBRAS
                </div>
                <div className='service-carousel-container'>
                  <Slider {...settings}>
                    { obras.map( (item, i) => (
                      <div align="center" key={ i + "_c" }>
                        <img
                          className="services-carousel"
                          src={item}
                        />
                      </div>
                    )) }
                  </Slider>
                </div>
                      
              </div>
              <div className="footer-andrea">
                <div className="legend-andrea">
                  "Sentir lo invisible y ser capaz de crearlo, eso es arte"
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
