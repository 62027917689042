import { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

import { users } from './json/opticas';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Slider from "react-slick";

import business from './img/opticas/business.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

import { BoxArrowUpRight, EnvelopeAtFill, Facebook, Globe, Instagram, Linkedin, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons'

import servicio1 from './img/opticas/service1.png'
import servicio2 from './img/opticas/service2.png'
import servicio3 from './img/opticas/service3.png'
import servicio4 from './img/opticas/service4.png'

const containerStyle = {
  width: '90%',
  height: '250px'
};

const center = {
  lat: 19.6542518,
  lng: -99.2080879
};

const centerMark = {
  lat: 19.6542518,
  lng: -99.2080879
};

function App({ path }) {
  const services = [ servicio1, servicio2, servicio3, servicio4 ];
  const [markerPoint, setMarkerPoint] = useState('');

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };

  const handleMarker = () => {
    console.log("click")
    window.open("https://goo.gl/maps/AzrKRz1c2w6ShVai6")
  }

  const onLoad = map => {
    setTimeout(() => {
      setMarkerPoint(<Marker position={centerMark} onClick={ handleMarker }/>)
    }, 1000);
  }

  return (
    <div className="App" align="center">
      <div>
        <Container className="card-container">
          <Row>
            <div style={{ position: "relative", padding: 0 }}>
              <div className='background-optica'></div>
              <div style={{ position: "relative", paddingLeft: '15px', paddingRight: '15px' }}>

                { path &&
                  <>
                    <div>
                      <img className='business-image' src={ business }></img>
                    </div>
                    <div>
                      <img className="profile-image" src={ users[path].profile }></img>
                    </div>
                    <div className="profile-name">{ users[path].name }</div>
                    <div className="profile-job">{ users[path].job }</div>

                    <div className="contact-container">
                      <div className='subtitle'>Contáctame</div>
                      <Facebook className="icons-contact-optica" onClick={ () =>  window.open('https://www.facebook.com/GrupoOpticoJardines') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Instagram className="icons-contact-optica" onClick={ () =>  window.open('https://www.instagram.com/opticajardines/') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Tiktok className="icons-contact-optica" onClick={ () =>  window.open('https://www.tiktok.com/@opticajardines') }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Whatsapp className="icons-contact-optica" onClick={ () =>  window.open( users[path].whats ) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <EnvelopeAtFill className="icons-contact-optica" onClick={ () =>  window.open('mailto:' + users[path].email) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <TelephoneFill className="icons-contact-optica" onClick={ () =>  window.open('tel://' + users[path].tel) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Globe className="icons-contact-optica" onClick={ () =>  window.open('https://grupoopticojardines.com.mx/') }/>
                    </div>
                  </>
                }

                <div className='business-desc'>
                  “No todos los ojos cerrados duermen, ni todos los ojos abiertos ven.”
                </div>

                <div className="section-title">
                  ACERCA DE NOSOTROS
                </div>
                <div className='business-desc_a'>
                  Somos especialistas de atención primaria en salud ocular, con un grupo de optometristas de primer nivel, que van a garantizar la mejor atención para determinar cuál es la mejor solución que se adapte a sus necesidades.
                </div>
                <br/>
                <div className="section-title">
                  ALGUNOS DE NUESTROS SERVICIOS
                </div>
                <div className='service-carousel-container'>
                  <Slider {...settings}>
                    { services.map( (item, i) => (
                      <div align="center" key={ i + "_c" }>
                        <img
                          className="services-carousel"
                          src={item}
                        />
                      </div>
                    )) }
                  </Slider>
                </div>
                      
                <div className="section-title">
                  VISITA NUESTRA CLÍNICA
                </div>
                <br/>
                <div>
                  <div className='map' align="center">
                    <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={ 16 }
                        onLoad = { onLoad }
                      >
                        { markerPoint }

                      </GoogleMap>
                    
                    </LoadScript>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="footer-optica">
                <div className="legend-optica">
                  Copyright © 2022 | Powered by [Eureka Marketing]
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
