import { initializeApp } from "firebase/app"
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'


const firebaseConfig = {
  apiKey: "AIzaSyCmAkEobkTkXBXU7Lk9lxeukapyWHx75z4",
  authDomain: "es-mi-tarjeta.firebaseapp.com",
  databaseURL: "https://es-mi-tarjeta-default-rtdb.firebaseio.com",
  projectId: "es-mi-tarjeta",
  storageBucket: "es-mi-tarjeta.appspot.com",
  messagingSenderId: "541444524714",
  appId: "1:541444524714:web:f21650ba8c13f700de9ed3",
  measurementId: "G-3T5PGZT1BV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database }