import aroi from '../img/general/aroi.jpg'
import canigatinos from '../img/general/canigatinos.jpg'

export const generalList = [ 'MarioChaparro', 'JorgeAlvarez' ]

export const users = {
    MarioChaparro: {
        path: 'MarioChaparro',
        component: 'General',
        job: 'Director General',
        profile: canigatinos,
        name: "Mario Chaparro",
        company: "Canigatinos",
        whats: '55 1821 1715',
        workphone: '',
        email: 'mario.chaparro@canigatinos.com.mx',
        facebook: 'https://www.facebook.com/CANIGATINOS/',
        instagram: 'https://www.instagram.com/canigatinos/?hl=es-la',
        tiktok: 'https://www.tiktok.com/@canigatinos_oficial20',
        web: 'https://www.canigatinos.com.mx',
        slogan: 'Si es Canigatinos, es Conford Garantizado.'
    },
    JorgeAlvarez: {
        path: 'JorgeAlvarez',
        component: 'General',
        job: 'Ventas Pyme',
        profile: aroi,
        name: "ISC. Jorge Alvarez",
        company: "AroiServices",
        whats: '55 2898 3997',
        workphone: '',
        email: 'jorge.alvarez@aroiservices.mx',
        facebook: '',
        instagram: '',
        tiktok: '',
        web: 'https://aroiservices.mx',
        slogan: 'Mantenimiento, Ventas y Soporte de equipos PC, Mac, Redes y Servidores'
    }
}