import { useState, useEffect } from 'react';
import { users } from './json/nfc';
import PhotoAlbum from "react-photo-album";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

//import StarfieldAnimation from 'react-starfield-animation'
import vCardsJS from "vcards-js";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import './nfc.css';

import { AspectRatioFill, Basket2Fill, BasketFill, BoxArrowUpRight, CarFrontFill, EnvelopeAtFill, Eyedropper, Facebook, GeoAltFill, Globe, Instagram, Linkedin, List, ListTask, PersonLinesFill, Telegram, TelephoneFill, Tiktok, Twitter, Whatsapp, X } from 'react-bootstrap-icons'

function NFC( { path } ) {
  console.log('NFC path', path)
  const [ faceLink, setFaceLink ] = useState(users[path].facebook)
  const [ instaLink, setInstaLink ] = useState(users[path].instagram)
  const [ tiktokLink, setTiktokLink ] = useState(users[path].tiktok)
  const [ linkedLink, setLinkedLink ] = useState(users[path].linkedin)
  const [ loading, setLoading ] = useState(true);
  const [ vData, setVData ] = useState("")
  const [ mainItems, setMainItems ] = useState(0)

  useEffect(() => {
    /*if ( isMobile ) {
      if( isAndroid ) {
        setFaceLink('fb://page/1639569549659215')
      }
      else if( isIOS ) {
        setFaceLink('fb://profile/1639569549659215')
      }
    }*/
   console.log('NFC componente', path)

    var count = 0
    if( users[path].workphone ) count++
    if( users[path].whats ) count++
    if( users[path].email ) count++

    if( count == 1 ) setMainItems( 12 )
    else if( count == 2) setMainItems( 6 )
    else if( count == 3) setMainItems( 4 )

    var vcf = vCardsJS();
 
    //set properties
    vcf.firstName = users[path].name;
    //vcf.middleName = 'Esteban';
    vcf.lastName = users[path].lastName;
    vcf.organization = users[path].company;
    //vcf.photo.attachFromUrl(photo);
    vcf.cellPhone = users[path].whats;
    vcf.workEmail = users[path].email;
    vcf.workPhone = users[path].workphone;
    //vcf.birthday = new Date(1986, 4, 13);
    vcf.title = users[path].job;
    if( users[path].web ) vcf.url = users[path].web;
    //vcf.note = 'Notes on Eric';

    setVData(vcf.getFormattedString())

    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, []);

  const handleClick = (eventKey) => {
    if (isMobile) {
      //var url = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(vcf.getFormattedString());
      //document.location.href = url;
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('fxios') > -1;

      if(isFirefox){
        document.location.href = '/'
      } else {
        var uri = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(vData);

        var link = document.createElement("a");
        link.download = 'pabloMonroy.vcf';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', `data:,${vData}`);
      linkElement.setAttribute('download', users[path].name + '.vcf');
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div className="App" align="center">
      <div className='header-cont'>
        {/*<StarfieldAnimation
          style={{
            position: 'absolute',
            width: '100%',
            height: '1070px'
          }}
        />*/}
        <Container className="card-container" style={ users[path].textColor ? { color: users[path].textColor } : { color: '#000' } }>
          <Container id="main" className="animate__animated animate__bounce animate__fadeIn animate__delay-3s">
            <Row>
              <div style={{ position: "relative", padding: 0 }}>

                <div className='business-container'>
                  <img className='business-image' src={ users[path].logo }></img>
                </div>

                { users[path].profile &&
                  <div className='user-profile user-profile' style={{ backgroundImage: 'url(' + users[path].profile + ')' }}>
                    {/* <img className="profile" src={ users[path].profile }></img> */}
                  </div>
                }

                <div style={{ position: "relative", paddingLeft: '20px', paddingRight: '20px' }} align='center'>
                  <br/>
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-4s">                  
                    <div className="profile-name">{ users[path].name + " " + users[path].lastName }</div>
                    <div className="profile-job" style={{ color: users[path].color }}>{ users[path].job }</div>
                    { users[path].status != null && <div style={ users[path].status ? { color: 'green', fontSize: '25px', fontWeight: 800 } : { color: 'red', fontSize: '30px', fontWeight: 800 } }> { users[path].status ? 'En funciones' : '¡Inactivo! Favor de reportarlo a la autoridad competente' } </div> }
                    { ( users[path].status == null || users[path].status ) && users[path].slogan && <div className='business-desc'>{ users[path].slogan }</div> }
                  </div>
                  
                  { ( users[path].status == null || users[path].status ) &&
                    <>
                      { mainItems > 0 &&
                        <>
                          <br/>
                          <div>
                            <Container className="animate__animated animate__bounce animate__fadeIn animate__delay-5s">
                              <Row className='contact-style-original' style={{ background: users[path].color }}>
                                { users[path].workphone && 
                                  <Col md={ mainItems } xs={ mainItems } className="pipe-option" >
                                    <a href={ "tel:" + users[path].workphone.replace(/ /g, '') }>
                                      <TelephoneFill className='icon-class'/>
                                      <div className='icon-text'>Llamar</div>
                                    </a>
                                  </Col>
                                }
                                { users[path].whats && 
                                  <Col md={ mainItems } xs={ mainItems } className="pipe-option" onClick={ () => window.open("https://wa.me/521" + users[path].whats.replace(/ /g, '') + "?text=Deseo%20más%20información", "_blank")}>
                                    <Whatsapp className='icon-class'/>
                                    <div className='icon-text'>Mensaje</div>
                                  </Col>
                                }
                                { users[path].email && 
                                  <Col md={ mainItems } xs={ mainItems }>
                                    <a href={ "mailto:" + users[path].email.replace(/ /g, '') }>
                                      <EnvelopeAtFill className='icon-class'/>
                                      <div className='icon-text'>Correo</div>
                                    </a>
                                  </Col>
                                }
                              </Row>
                            </Container>
                          </div>
                        </>
                      }

                      <br/>
                      <div className="animate__animated animate__bounce animate__fadeIn animate__delay-5s">
                        <Container>
                          { users[path].workphone && 
                            <a href={ "tel:" + users[path].workphone.replace(/ /g, '') }>
                              <Row className='row-list'>
                                <Col md={2} xs={2} className='icon-list'>
                                  <TelephoneFill className='icon-class-list'/>
                                </Col>
                                <Col md={8} xs={8} className='text-list' >
                                  <div className='icon-text-title'>Teléfono</div>
                                  <div className='icon-text-date'>{ users[path].workphone }</div>
                                </Col>
                                <Col md={2} xs={2}>
                                  <BoxArrowUpRight className='icon-class-list icon-small'/>
                                </Col>
                              </Row>
                            </a>
                          }
                          { users[path].whats && 
                            <Row className='row-list' onClick={ () => window.open("https://wa.me/521" + users[path].whats.replace(/ /g, '') + "?text=Deseo%20más%20información")}>
                              <Col md={2} xs={2} className='icon-list'>
                                <Whatsapp className='icon-class-list'/>
                              </Col>
                              <Col md={8} xs={8} className='text-list'>
                                <div className='icon-text-title'>Whatsapp</div>
                                <div className='icon-text-date'>{ users[path].whats }</div>
                              </Col>
                              <Col md={2} xs={2}>
                                <BoxArrowUpRight className='icon-class-list icon-small'/>
                              </Col>
                            </Row>
                          }
                          { users[path].email && 
                            <a href={ "mailto:" + users[path].email }>
                              <Row className='row-list'>
                                <Col md={2} xs={2} className='icon-list'>
                                  <EnvelopeAtFill className='icon-class-list'/>
                                </Col>
                                <Col md={8} xs={8} className='text-list'>
                                  <div className='icon-text-title'>Correo electrónico</div>
                                  <div className='icon-text-date'>{ users[path].email }</div>
                                </Col>
                                <Col md={2} xs={2}>
                                  <BoxArrowUpRight className='icon-class-list icon-small'/>
                                </Col>
                              </Row>
                            </a>
                          }
                          { users[path].emailAlt && 
                            <a href={ "mailto:" + users[path].emailAlt }>
                              <Row className='row-list'>
                                <Col md={2} xs={2} className='icon-list'>
                                  <EnvelopeAtFill className='icon-class-list'/>
                                </Col>
                                <Col md={8} xs={8} className='text-list'>
                                  <div className='icon-text-title'>Correo electrónico Alternativo</div>
                                  <div className='icon-text-date'>{ users[path].emailAlt }</div>
                                </Col>
                                <Col md={2} xs={2}>
                                  <BoxArrowUpRight className='icon-class-list icon-small'/>
                                </Col>
                              </Row>
                            </a>
                          }
                          { users[path].address && 
                            <a href={ users[path].map }>
                              <Row className='row-list'>
                                <Col md={2} xs={2} className='icon-list'>
                                  <GeoAltFill className='icon-class-list'/>
                                </Col>
                                <Col md={8} xs={8} className='text-list'>
                                  <div className='icon-text-title'>Dirección</div>
                                  <div className='icon-text-date'>{ users[path].address }</div>
                                </Col>
                                <Col md={2} xs={2}>
                                  <BoxArrowUpRight className='icon-class-list icon-small'/>
                                </Col>
                              </Row>
                            </a>
                          }
                          { users[path].services &&
                            <a href={ users[path].services }>
                              <Row className='row-list'>
                                <Col md={2} xs={2} className='icon-list'>
                                  <Basket2Fill className='icon-class-list'/>
                                </Col>
                                <Col md={8} xs={8} className='text-list'>
                                  <div className='icon-text-title'>Servicios</div>
                                  <div className='icon-text-date'>{ 'Abrir' }</div>
                                </Col>
                                <Col md={2} xs={2}>
                                  <BoxArrowUpRight className='icon-class-list icon-small'/>
                                </Col>
                              </Row>
                            </a>
                          }
                          {  users[path].car &&
                            <Row className='row-list'>
                              <Col md={2} xs={2} className='icon-list'>
                                <CarFrontFill className='icon-class-list'/>
                              </Col>
                              <Col md={8} xs={8} className='text-list'>
                                <div className='icon-text-title'>Vehículo</div>
                                <div className='icon-text-date'>{ users[path].car }</div>
                              </Col>
                            </Row>
                          }
                          {  users[path].placas &&
                            <Row className='row-list'>
                              <Col md={2} xs={2} className='icon-list'>
                                <AspectRatioFill className='icon-class-list'/>
                              </Col>
                              <Col md={8} xs={8} className='text-list'>
                                <div className='icon-text-title'>Placas</div>
                                <div className='icon-text-date'>{ users[path].placas }</div>
                              </Col>
                            </Row>
                          }

                          { users[path].productList &&
                            <Row className='row-list'>
                              <Col md={2} xs={2} className='icon-list'>
                                <div className='icon-class-list'> <img width='28px' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMElEQVR4nO2Yu2tUQRTGJ4WvIj4bXwERfFQqigjqH7C47HzfXLkBsUghptfCWFiIiChKQPF/CISQRhIbbXxgGkFERRsD8YGIgolGYqK5csgsLmuud+7mPtUPTrGzO+ec38w5w+wolYEAdJAcIDlhbVBrvU2VSQA6AHwkGTSajBljNqqyiHM7IckPSeJiAIYtTL8qizhXSkHj6ttSE7hxVRYB+BAGIt+psghAn0162PaL2E071qeKIpL3mxs5rgG4VwSQIAnLm0P9B2HBdgTA3QR25U7eHH+fGLNEClNS/wwIIm68pQCBw423FCB0uPGWBWQi6sZbehAAn5p/XyS1NYEMht14pewKC0Ky0yY2Kp/ldAprds/zttg5o3bskCqCurq6lpJ8KUlprU/Ux22D90tPWBuoQ4hInrQgL3zfX6zyFoCzNqFnlUplSZwFkDl2t86oPAVgF4BpAD88zzsQd77Weh+A7yRnSO5Reai7u3sRgId2RS+36ofkFbujj3IpMZLnGmp8Wat+ZK74sAtyTmUpY8zuekmRPLhQf+JDfAGYFt8qC0lDk3xsV/BSUn5J9tYPDTkIVNoCcCGp/+l/eFU5n0lJpQ1CcsYYszfNknqSAUSQaonJdmcFwV+W7ClGcivJqaxBAHzzPG97UhxtAG5HBQRwSmu9XgxAj4wlBHQrEQpjzFGHletpnidjCe7OkQVBVCqV5QDeRgUyxqxrnuv7/toEQd4BWNkyiNyhXAJJOTXPrVarGxLumYstQUgiAL46NuVvpUXydMjvxwAc1lq3iwEgyecOcSbnW7BIAbjqulq22Xscmn3M9/3V8yzaKvmO0bF6Y0H4vr+i/oCQpMlOhMU0xvgOPsalb51BSB5PGsL2UntYTEmQbotxLA7IUNYgtgoCB5AbcUDepAEije3wEhNE2Os4IJNpgMjpJI09T7w1AF45+ph0Bml43UjDxqSxpSdsX3TGgAhIPo0Dcj1FkIXaNWcQz/M2k/xSgKSDRgPwGcAmZxC7K7U8ru4MtyljTFW1ImPMTpIjeUMAeFCr1Xa0BNEoeUW0D2kjAN6TnE0x8VmJIclLTAD7XZL8CdH1QIYy7aQSAAAAAElFTkSuQmCC"></img> </div>
                              </Col>
                              <Col md={8} xs={8} className='text-list'>
                                <div className='icon-text-title'>Portafolio de Productos</div>
                                <div className='icon-text-date'>
                                  { users[path].productList.map( item => (
                                    <span> { item + ' · '} </span>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          }

                          { users[path].serviceList &&
                            <Row className='row-list'>
                              <Col md={2} xs={2} className='icon-list'>
                                <ListTask className='icon-class-list'/>
                              </Col>
                              <Col md={8} xs={8} className='text-list'>
                                <div className='icon-text-title'>Servicios</div>
                                <div className='icon-text-date'>
                                  { users[path].serviceList.map( item => (
                                    <span> { item + ' · '} </span>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          }
                          
                          { mainItems > 0 &&
                            <>
                              <br/>
                              <br/>
                              <Row>
                                <Col md={12} xs={12}>
                                  <Button className="contact-btn" style={{ background: users[path].color }} onClick={ () => handleClick() }><PersonLinesFill className='icon-class-list icon-small' style={{ top: '-2px', color: '#fff' }}/>&nbsp;&nbsp; GUARDAR CONTACTO</Button>{' '}
                                </Col>
                              </Row>
                            </>
                          }

                          { users[path].gallery &&
                            <Row>
                              <Col>
                                <br/>
                                <br/>
                                <PhotoAlbum layout="columns" photos={ users[path].gallery } columns={ 2 } />
                              </Col>
                            </Row>
                          }

                          <br/>
                          {/*<div className='social'>Social Media</div>*/}
                          <br/>
                          <Row style={{ padding: '0px 28%' }}>

                            { users[path].facebook && 
                              <Col md={3} xs={3}>
                                <a href={ faceLink }>
                                  <Facebook className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }

                            { users[path].instagram && 
                              <Col md={3} xs={3} >
                                <a href={ instaLink }>
                                  <Instagram className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }

                            { users[path].tiktok && 
                              <Col md={3} xs={3}>
                                <a href={ tiktokLink }>
                                  <Tiktok className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }

                            { users[path].linkedin && 
                              <Col md={3} xs={3}>
                                <a href={ linkedLink }>
                                  <Linkedin className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }

                            { users[path].telegram && 
                              <Col md={3} xs={3}>
                                <a href={ users[path].telegram }>
                                  <Telegram className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }

                            { users[path].x && 
                              <Col md={3} xs={3}>
                                <a href={ users[path].x }>
                                  <Twitter className='icon-class-list icon-small-big'/>
                                </a>
                              </Col>
                            }
                          </Row>

                          { users[path].web && 
                            <Row>
                              <Col>
                                <div className='web-text' onClick={ () => window.open(users[path].web, "_blank")}>{ users[path].web.replace("http://", '').replace("https://", '') }</div>
                              </Col>
                            </Row>
                          }
                          
                        </Container>
                        
                      </div>
                    </>
                  }
                  <br/>

                  <div style={{ fontSize: '13px' }}>  
                    <b>Desarrollado por:</b> <a href="https://www.facebook.com/profile.php?id=100094682870211&mibextid=LQQJ4d"> esmitarjeta.com.mx </a>
                  </div>
                  <br/>
                </div>
              </div>
            </Row>
          </Container>
          
        </Container>
      </div>
      <Modal
        show={loading}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className='animate__animated animate__bounce animate__fadeIn animate__duration-2s' align="center">
            <img className='loading-logo' src={ users[path].loading }></img>
            <div>
              <Spinner animation="grow" className='spinner-custom spinner-a' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-b' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-c' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-d' />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NFC;
