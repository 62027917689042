import { useState, useEffect } from 'react';
import { users } from './json/grl';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

//import StarfieldAnimation from 'react-starfield-animation'
import vCardsJS from "vcards-js";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import './GrlCss.css';

import { BoxArrowUpRight, EnvelopeAtFill, Facebook, Globe, Instagram, Linkedin, PersonLinesFill, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons'

function Grl({ path }) {
  const [ faceLink, setFaceLink ] = useState(users[path].facebook)
  const [ instaLink, setInstaLink ] = useState(users[path].instagram)
  const [ tiktokLink, setTiktokLink ] = useState(users[path].tiktok)
  const [ loading, setLoading ] = useState(true);
  const [ vData, setVData ] = useState("")

  useEffect(() => {
    /*if ( isMobile ) {
      if( isAndroid ) {
        setFaceLink('fb://page/1639569549659215')
      }
      else if( isIOS ) {
        setFaceLink('fb://profile/1639569549659215')
      }
    }*/

    var vcf = vCardsJS();
 
    //set properties
    vcf.firstName = users[path].name;
    //vcf.middleName = 'Esteban';
    //vcf.lastName = 'Monroy';
    vcf.organization = users[path].company;
    //vcf.photo.attachFromUrl(photo);
    vcf.cellPhone = users[path].whats;
    vcf.workEmail = users[path].email;
    vcf.workPhone = users[path].workphone ? users[path].workphone : '';
    //vcf.birthday = new Date(1986, 4, 13);
    vcf.title = users[path].job;
    vcf.url = users[path].web;
    //vcf.note = 'Notes on Eric';

    setVData(vcf.getFormattedString())

    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, []);

  const handleClick = (eventKey) => {
    if (isMobile) {
      //var url = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(vcf.getFormattedString());
      //document.location.href = url;
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('fxios') > -1;

      if(isFirefox){
        document.location.href = 'https://esmitarjeta.com.mx/' + path + '.vcf'
      } else {
        var uri = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(vData);

        var link = document.createElement("a");
        link.download = path + '.vcf';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', `data:,${vData}`);
      linkElement.setAttribute('download', path + '.vcf');
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div className="App" align="center">
      <div className='header-cont'>
        {/*<StarfieldAnimation
          style={{
            position: 'absolute',
            width: '100%',
            height: '1070px'
          }}
        />*/}
        <Container className="card-cont">
          <Container id="main" className="animate__animated animate__bounce animate__fadeIn animate__delay-3s">
            <Row>
              <div style={{ position: "relative", padding: 0 }}>


                <div>
                  <img className="simple-profile" src={ users[path].profile }></img>
                </div>

                <div style={{ position: "relative", paddingLeft: '20px', paddingRight: '20px' }} align='center'>
                  <br/>
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-4s">                  
                    <div className="prof-name" style={{ textAlign: 'center' }}>{ users[path].name }</div>
                    <div className="prof-job" style={{ textAlign: 'center' }}>{ users[path].job }</div>
                    <div className='bus-desc' style={{ textAlign: 'center' }}>{ users[path].slogan }</div>
                  </div>
                  
                  <br/>
                  <div>
                    <Container className="animate__animated animate__bounce animate__fadeIn animate__delay-5s">
                      <Row className='contact-style'>
                        { users[path].workphone != '' && 
                          <Col md={ 4 } xs={ 4 } className="pipe-option" >
                            <a href={ 'tel:'+ users[path].workphone }>
                              <TelephoneFill className='icon-class'/>
                              <div className='icon-text'>Llamar</div>
                            </a>
                          </Col>
                        }
                        <Col md={ users[path].workphone != '' ? 4 : 6 } xs={ users[path].workphone != '' ? 4 : 6 } className="pipe-option" onClick={ () => window.open("https://wa.me/521" + users[path].whats.replace(/\s/g, '') + "?text=Deseo%20más%20información", "_blank")}>
                          <Whatsapp className='icon-class'/>
                          <div className='icon-text'>Mensaje</div>
                        </Col>
                        <Col md={ users[path].workphone != '' ? 4 : 6 } xs={ users[path].workphone != '' ? 4 : 6 }>
                          <a href={ "mailto:" + users[path].email }>
                            <EnvelopeAtFill className='icon-class'/>
                            <div className='icon-text'>Correo</div>
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <br/>
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-5s">
                    <Container>
                      { users[path].workphone != '' && 
                        <a href={ "tel:" + users[path].workphone }>
                          <Row className='row-list'>
                            <Col md={2} xs={2} className='icon-list'>
                              <TelephoneFill className='icon-class-list'/>
                            </Col>
                            <Col md={8} xs={8} className='text-list' >
                              <div className='icon-text-title'>Teléfono</div>
                              <div className='icon-text-date'>{ users[path].workphone }</div>
                            </Col>
                            <Col md={2} xs={2}>
                              <BoxArrowUpRight className='icon-class-list icon-small'/>
                            </Col>
                          </Row>
                        </a>
                      }
                      <Row className='row-list' onClick={ () => window.open("https://wa.me/521" + users[path].whats.replace(/\s/g, '') + "?text=Deseo%20más%20información")}>
                        <Col md={2} xs={2} className='icon-list'>
                          <Whatsapp className='icon-class-list'/>
                        </Col>
                        <Col md={8} xs={8} className='text-list'>
                          <div className='icon-text-title'>Whatsapp</div>
                          <div className='icon-text-date'>{ users[path].whats }</div>
                        </Col>
                        <Col md={2} xs={2}>
                          <BoxArrowUpRight className='icon-class-list icon-small'/>
                        </Col>
                      </Row>
                      <a href={ "mailto:" + users[path].email }>
                        <Row className='row-list'>
                          <Col md={2} xs={2} className='icon-list'>
                            <EnvelopeAtFill className='icon-class-list'/>
                          </Col>
                          <Col md={8} xs={8} className='text-list'>
                            <div className='icon-text-title'>Correo electrónico</div>
                            <div className='icon-text-date'>{ users[path].email }</div>
                          </Col>
                          <Col md={2} xs={2}>
                            <BoxArrowUpRight className='icon-class-list icon-small'/>
                          </Col>
                        </Row>
                      </a>
                      <br/>
                      <br/>
                      <Row>
                        <Col md={12} xs={12}>
                          <Button className="contact-btn" onClick={ () => handleClick() }><PersonLinesFill className='icon-class-list icon-small' style={{ top: '-2px', color: '#fff' }}/>&nbsp;&nbsp; GUARDAR CONTACTO</Button>{' '}
                        </Col>
                      </Row>

                      <br/>
                      {/*<div className='social'>Social Media</div>*/}
                      <br/>
                      <Row style={{ padding: '0px 28%' }}>
                        { users[path].facebook != '' && 
                          <Col md={4} xs={4}>
                            <a href={ faceLink }>
                              <Facebook className='icon-class-list icon-small-big'/>
                            </a>
                          </Col>
                        }
                        { users[path].instagram != '' && 
                          <Col md={4} xs={4} >
                            <a href={ instaLink }>
                              <Instagram className='icon-class-list icon-small-big'/>
                            </a>
                          </Col>
                        }
                        { users[path].tiktok != '' && 
                          <Col md={4} xs={4}>
                            <a href={ tiktokLink }>
                              <Tiktok className='icon-class-list icon-small-big'/>
                            </a>
                          </Col>
                        }
                      </Row>
                      <Row>
                        <Col>
                          <div className='web-text' onClick={ () => window.open( users[path].web, "_blank")}>{ users[path].web.replace('https://', '') }</div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <br/>
                  <br/>

                </div>
              </div>
            </Row>
          </Container>
          
        </Container>
      </div>
      <Modal
        show={loading}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className='animate__animated animate__bounce animate__fadeIn animate__duration-2s' align="center">
            <img className='loading-logo' src={ users[path].profile }></img>
            <div>
              <Spinner animation="grow" className='spinner-custom spinner-a' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-b' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-c' />&nbsp;&nbsp;&nbsp;
              <Spinner animation="grow" className='spinner-custom spinner-d' />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Grl;
