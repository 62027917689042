import { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './App.css';

const containerStyle = {
  width: '100%',
  height: '350px'
};

const center = {
  lat: 19.6972624,
  lng: -99.2230682
};

const centerMark = {
  lat: 19.6972624,
  lng: -99.2230682
};

function App({ path }) {
  const [markerPoint, setMarkerPoint] = useState('');

  const handleMarker = () => {
    console.log("click")
    window.open("https://goo.gl/maps/AzrKRz1c2w6ShVai6")
  }

  const onLoad = map => {
    setTimeout(() => {
      setMarkerPoint(<Marker position={centerMark} onClick={ handleMarker }/>)
    }, 1000);
  }

  return (
    <div className="App" align="center">
      <div>
        <Container className="card-container">
          <Row>
            <div style={{ position: "relative", padding: 0 }}>
              <div style={{ position: "relative", paddingLeft: '15px', paddingRight: '15px' }}>
                <br/>
                <div className='business-container'>P.º de la Ternura 1, Urbi Quinta Montecarlo, 54715 Cuautitlán Izcalli, Méx.</div>
                <br/>
                <div className='map'>
                  <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={ 16 }
                      onLoad = { onLoad }
                    >
                      { markerPoint }

                    </GoogleMap>
                  
                  </LoadScript>
                </div>
                <br/>

              </div>

              <div className="footer-israel">
                <div className="legend-israel">
                  "Sé tan bueno que no puedan ignorarte"
                </div>
              </div>

            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
