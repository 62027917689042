import optica from '../img/opticas/perfil/optica.png'

export const opticasList = [ 'Opticajardines', 'Maribeltorres' ]

export const users = {
    Opticajardines: {
        path: 'Opticajardines',
        component: 'Opticas',
        profile: optica,
        name: "Grupo Óptico Jardines",
        whats: 'https://wa.link/qa50f5',
        tel: '5581912834',
        email: 'lab.optico.jardines@gmail.com'
    },
    Maribeltorres: {
        path: 'Maribeltorres',
        component: 'Opticas',
        name: "Maribel Torres",
        profile: optica,
        whats: 'https://wa.link/d94acy',
        tel: '5576675716',
        email: 'lab.optico.jardines@gmail.com'
    }
}