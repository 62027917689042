export const israelList = [ 'Israelnochebuena' ]

export const users = {
    Israelnochebuena: {
        path: 'Israelnochebuena-cruz',
        component: 'Israel',
        profile: '',
        name: '',
        job: '',
        tel: '',
        email: '',
        whats: ''
    }
}